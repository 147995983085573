import { Paper, Text, Title, Group } from "@mantine/core";

export function MetricCard({ title, value, trend, color }: { title: string, value: string, trend: string, color: string }) {
  return (
    <Paper shadow="xs" p="md" radius="md" withBorder style={{ minHeight: 100 }}>
      <Text size="sm" fw={500}>
        {title}
      </Text>
      <Group align="flex-end" justify="space-between" mt="xs">
        <Title order={3}>{value}</Title>
        <Text size="sm" c={color}>
          {trend}
        </Text>
      </Group>
    </Paper>
  );
}
