import {
    Text,
    Title,
    Grid,
    Paper,
    Box,
    Badge,
    Group,
    Stack,
    Button,
  } from "@mantine/core";
  import { PatientCard } from "../../components/PatientCard";
  import { MetricCard } from "../../components/MetricCard";
  
  const demoMetrics = [
    { title: "Avg. Session Score", value: "7.4", trend: "+3%", color: "green" },
    { title: "Active Patients", value: "10", trend: "", color: "blue" },
    { title: "At Risk Patients", value: "2", trend: "+1", color: "red" },
  ];
  
  const demoPatients = [
    {
      name: "Seth Malone",
      date: "Tuesday",
      time: "2:00 PM",
      summary: "Talked about school anxiety and confidence.",
      tags: ["bravery", "happiness"],
    },
    {
      name: "Terra Foster",
      date: "Wednesday",
      time: "10:30 AM",
      summary: "Worked on breathing exercises and sleep.",
      tags: ["anxiety", "connection"],
    },
  ];
  
  const therapistMetrics = {
    sessionsCompleted: 3,
    reflectionDepth: 3.9,
    notesPending: 2,
  };
  
  export default function HomePage() {
    return (
      <Box p="md">
        <Title order={2}>Hey Yosef 👋</Title>
        <Text mt="xs">You have 6 patients this week, 2 notes left to write</Text>
        <Group mt="xs">
          <Badge color="gray">presence</Badge>
          <Badge color="gray">curiosity</Badge>
          <Badge color="gray">non-judgment</Badge>
        </Group>
  
        <Grid mt="lg">
          {demoMetrics.map((metric, idx) => (
            <Grid.Col span={{ base: 12, sm: 4 }} key={idx}>
              <MetricCard {...metric} />
            </Grid.Col>
          ))}
        </Grid>
  
        <Grid mt="xl">
          {/* Left: Therapist Metrics */}
          <Grid.Col span={{ base: 12, md: 7 }}>
            <Title order={4} mb="md">Therapist Metrics</Title>
            <Paper p="md" withBorder radius="md">
              <Stack gap="xs">
                <Group justify="space-between">
                  <Text>Sessions Completed</Text>
                  <Text fw={500}>{therapistMetrics.sessionsCompleted}</Text>
                </Group>
                <Group justify="space-between">
                  <Text>Avg. Reflection Depth</Text>
                  <Text fw={500}>{therapistMetrics.reflectionDepth}</Text>
                </Group>
                <Group justify="space-between">
                  <Text>Reports Pending</Text>
                  <Text fw={500}>{therapistMetrics.notesPending}</Text>
                </Group>
              </Stack>
            </Paper>
  
            <Group mt="lg">
              <Button size="xs" variant="light">Add Patient</Button>
              <Button size="xs" variant="light">Write Today’s Notes</Button>
            </Group>
          </Grid.Col>
  
          {/* Right: Patient List */}
          <Grid.Col span={{ base: 12, md: 5 }}>
            <Title order={4} mb="md">This Week’s Patients</Title>
            <Stack>
              {demoPatients.map((p, idx) => (
                <PatientCard key={idx} {...p} />
              ))}
            </Stack>
          </Grid.Col>
        </Grid>
      </Box>
    );
  }
  