import {
  Box,
  Title,
  Select,
  Paper,
  Text,
  Group,
  Badge,
} from "@mantine/core";
import { useState } from "react";

const demoPatients = [
  {
    id: "1",
    name: "Seth Malone",
    age: 15,
    tags: ["bravery", "confidence"],
    lastSeen: "3 days ago",
  },
  {
    id: "2",
    name: "Terra Foster",
    age: 17,
    tags: ["connection", "sleep"],
    lastSeen: "1 week ago",
  },
  {
    id: "3",
    name: "Noa Levi",
    age: 13,
    tags: ["anger", "boundaries"],
    lastSeen: "yesterday",
  },
];

export default function PatientListPage() {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const selected = demoPatients.find((p) => p.id === selectedId);

  return (
    <Box p="md">
      <Title order={2} mb="md">
        Patients
      </Title>

      <Select
        label="Select a patient"
        placeholder="Choose..."
        data={demoPatients.map((p) => ({
          value: p.id,
          label: p.name,
        }))}
        value={selectedId}
        onChange={setSelectedId}
        searchable
        clearable
        mb="lg"
      />

      {selected && (
        <Paper p="md" withBorder radius="md">
          <Title order={3}>{selected.name}</Title>
          <Text size="sm" c="dimmed">
            Age: {selected.age}
          </Text>
          <Text size="sm" mt="sm">
            Last seen: {selected.lastSeen}
          </Text>

          <Group mt="sm">
            {selected.tags.map((tag, i) => (
              <Badge key={i} color="teal">
                {tag}
              </Badge>
            ))}
          </Group>

          <Text mt="lg">📓 Notes and progress will appear here...</Text>
        </Paper>
      )}
    </Box>
  );
}
