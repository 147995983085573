import {
    AppShell,
    NavLink,
    Text,
    ScrollArea,
    Group,
    Burger
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { Settings, LogOut, Home, Users } from "lucide-react";


export default function Shell({ children }: { children: ReactNode }) {
    const navigate = useNavigate();
    const [opened, { toggle }] = useDisclosure();


    return (
        <AppShell
            padding="md"
            navbar={{
                width: 240,
                breakpoint: "sm",
                collapsed: { mobile: !opened },
            }}
            header={{ height: 60 }}
        >
            {/* HEADER with Burger */}
            <AppShell.Header p="xs">
                <Group justify="space-between" h="100%">
                    <Text fw={700}>🧠 FriendApp</Text>
                    <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                </Group>
            </AppShell.Header>

            {/* NAVBAR (collapsible) */}
            <AppShell.Navbar p="xs" withBorder>
                <ScrollArea style={{ flex: 1 }}>
                    <NavLink
                        onClick={() => navigate("/home")}
                        label={
                            <Group gap="xs">
                                <Home size={18} />
                                <Text>Home</Text>
                            </Group>
                        }
                    />
                    <NavLink
                        onClick={() => navigate("/patients")}
                        label={
                            <Group gap="xs">
                                <Users size={18} />
                                <Text>Patients</Text>
                            </Group>
                        }
                    />
                </ScrollArea>

                {/* Footer links */}
                <Group justify="center" mt="lg" style={{ borderTop: "1px solid #eee", paddingTop: 8 }}>
                    <NavLink
                        onClick={() => navigate("/settings")}
                        label={
                            <Group gap="xs">
                                <Settings size={18} />
                                <Text>Settings</Text>
                            </Group>
                        }
                    />
                    <NavLink
                        onClick={async () => {
                            await signOut(auth);
                            navigate("/", { replace: true });
                        }}
                        label={
                            <Group gap="xs">
                                <LogOut size={18} />
                                <Text>Logout</Text>
                            </Group>
                        }
                    />
                </Group>
            </AppShell.Navbar>


            {/* MAIN CONTENT */}
            <AppShell.Main p="md" style={{ paddingTop: "80px" }}>
                {children}
            </AppShell.Main>

        </AppShell>
    );
}
