import { Paper, Text, Group, Badge } from "@mantine/core";

export function PatientCard({ name, date, time, summary, tags }: { name: string, date: string, time: string, summary: string, tags: string[]     }) {
  return (
    <Paper p="md" radius="md" withBorder>
      <Group justify="space-between">
        <Text fw={500}>{name}</Text>
        <Text c="dimmed">
          {date} – {time}
        </Text>
      </Group>
      <Text size="sm" mt="xs">{summary}</Text>
      <Group mt="xs">
        {tags.map((tag, i) => (
          <Badge key={i} color="indigo" variant="light">
            {tag}
          </Badge>
        ))}
      </Group>
    </Paper>
  );
}
