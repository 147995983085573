import { Navigate, RouteObject } from "react-router-dom";
import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import PatientListPage from "../pages/patients/PatientListPage";
import PatientDetailPage from "../pages/patients/PatientDetailPage";
import SettingsPage from "../pages/settings/SettingsPage";
import Shell from "./layout/Shell";


export const routes: RouteObject[] = [
  { path: "/", element: <LoginPage /> },
  { path: "/home", element: <Shell><HomePage /></Shell> },
  { path: "/patients", element: <Shell><PatientListPage /></Shell> },
  { path: "/patients/:id", element: <Shell><PatientDetailPage /></Shell> },
  { path: "/settings", element: <Shell><SettingsPage /></Shell> },

  { path: "*", element: <Navigate to="/home" replace /> },
];
